import * as React from 'react'

import { RenderAnnouncerType } from '@thg-commerce/gravity-elements'

export interface FormProps {
  /** Function to call when fields have successfully passed validation */
  onSubmit: (values, invalid?: boolean) => void
  style?: React.CSSProperties
  className?: string
  validateOnRender?: boolean
  fields: FormFieldProps[]
  children?: any
  renderAnnouncer?: RenderAnnouncerType
  csrf?: string
  onMinimize?: boolean
  dropdownForm?: boolean
}

const camelize = (str: string) => {
  return (
    str &&
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  )
}

export interface FormFieldProps {
  fieldName: string
  renderField: (ref: React.RefObject<any>, key: string) => JSX.Element | null
}

const renderForm = (fields: FormFieldProps[], refs: {}) => {
  return fields.map((field) => {
    return <FieldWrapper key={field.fieldName} field={field} refs={refs} />
  })
}

const FieldWrapper = (props: { field: FormFieldProps; refs: {} }) => {
  const ref = React.useRef(null)
  props.refs[camelize(props.field.fieldName)] = ref
  return props.field.renderField(ref, props.field.fieldName)
}

const getErrorFields = (checkErrors: {}): string => {
  return Object.keys(checkErrors).reduce((errorFields, ref) => {
    if (checkErrors[ref] !== null && checkErrors[ref].current !== null) {
      return `${errorFields} ${checkErrors[ref].current.label},`
    }
    return errorFields
  }, '')
}

export const DynamicForm = (props: FormProps) => {
  const refs = {}
  const [checkErrors, setErrors] = React.useState({})
  const [refsState, setRefsState] = React.useState({})
  const [errorsToAnnounce, setErrorsToAnnounce] = React.useState('')

  React.useEffect(() => {
    if (props.validateOnRender) {
      Object.keys(refs).forEach((ref) => {
        if (!refs[ref].current.validate()) {
          setRefsState(refs)
          setErrors(refs)
        }
      })
    }
  }, [])

  React.useEffect(() => {
    Object.keys(checkErrors).length !== 0
      ? setErrorsToAnnounce(
          `${Object.keys(checkErrors).length} error${
            Object.keys(checkErrors).length === 1 ? '' : 's'
          } in the form: ${getErrorFields(checkErrors)}`,
        )
      : setErrorsToAnnounce('')
  }, [checkErrors])

  React.useEffect(() => {
    Object.keys(checkErrors).forEach((ref) => {
      if (refsState[ref] !== null && refsState[ref].current !== null) {
        checkErrors[ref].current.validate()
      }
    })

    Object.keys(checkErrors).some((ref) => {
      if (refsState[ref] !== null && refsState[ref].current !== null) {
        if (!checkErrors[ref].current.validate()) {
          refs[ref].current.focus && refs[ref].current.focus()
          !props.dropdownForm && window.scrollTo(0, refs[ref].current.offsetTop)
          return true
        }
      }
      return false
    })
  }, [checkErrors])

  React.useEffect(() => {
    if (Object.keys(refsState).length !== 0) {
      let invalid = false
      const errorsToSet = Object.keys(refsState).reduce((errors, ref) => {
        if (refsState[ref] !== null && refsState[ref].current !== null) {
          if (!refsState[ref].current.validate()) {
            invalid = true
            errors[ref] = refsState[ref]
          }
        }
        return errors
      }, {})

      setErrors(errorsToSet)
      const values = {}
      Object.keys(refsState).forEach((ref) => {
        if (refsState[ref] !== null && refsState[ref].current !== null) {
          values[ref] =
            refsState[ref].current.value === 'true' ||
            refsState[ref].current.value === 'false'
              ? refsState[ref].current.value === 'true'
              : refsState[ref].current.value
        }
      })

      if (!invalid) {
        props.onSubmit(values)
      } else if (props.onMinimize) {
        props.onSubmit(values, invalid)
      }
    }
  }, [refsState])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setRefsState(refs)
  }

  const fields = renderForm(props.fields, refs)

  return (
    <form noValidate method="POST" onSubmit={handleSubmit}>
      {props.csrf && <input type="hidden" name="_csrf" value={props.csrf} />}
      <React.Fragment>
        {props.renderAnnouncer &&
          props.renderAnnouncer('assertive', errorsToAnnounce)}
        {fields}
        {props.children}
      </React.Fragment>
    </form>
  )
}
